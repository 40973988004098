import React from "react";
import Container from "../ui/Container";

const OurPartnersSection = () => {
  return (
    <section className="pb-16 md:pb-24">
      <Container>
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-5">
	  <div className="col-span-4 flex justify-center">
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            src="/assets/images/partner_epsilon.png"
            alt="..."
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            src="/assets/images/partner_ford.png"
            alt="..."
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            src="/assets/images/partner_insure.png"
            alt="..."
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-20 w-full object-contain lg:col-span-1"
            src="/assets/images/partner_dxc.png"
            alt="..."
            width={158}
            height={48}
          />
	</div>
        </div>
      </Container>
    </section>
  );
};

export default OurPartnersSection;
