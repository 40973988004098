import React, { useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const StepThree = ({ nextStep, previousStep, setUserInfo }) => {
  const [warning, setWarning] = useState(false);
  const [email, setEmail] = useState(""); // State to store email input value

  const validateEmail = (input) => {
      // Remove leading and trailing white spaces
      const cleanedInput = input.trim();

      // Split the input by space, comma, or semicolon
      const emailList = cleanedInput.split(/[ ,;]+/);

      // Regular expression for email validation
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      // Iterate through the email list and check each email
      for (const email of emailList) {
        if (!emailRegex.test(email)) {
          return false; // Invalid email found
        }
      }
      return true; // All emails are valid
  };

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      setWarning(true);
      // alert("Please fill in all fields before proceeding.");
    } else {
      let data = {
        email: email,
      };

      setUserInfo(data);
      nextStep();
    }
  };

  useEffect(() => {
    if (warning === true) {
      const timeoutId = setTimeout(() => {
        setWarning(false);
      }, 2000); // 2000 milliseconds = 2 seconds

      // Cleanup function to clear the timeout if triggerChange changes before the timeout
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [warning]);

  return (
    <fieldset>
      <div className="grid md:grid-cols-3 gap-8">
        <div className="md:col-span-2">
          <h1 className="mb-3 text-xl sm:text-2xl font-semibold">
            Enter the email address(es) to send the reports
          </h1>
          <ul className="grid md:grid-cols-2 gap-4">
            <li>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="you@example.com"
                  value={email} // Bind the input value to the email state
                  onChange={(e) => setEmail(e.target.value)} // Update the email state on input change
                />
              </div>
            </li>
          </ul>
          {warning === true && (
            <span style={{ color: "red" }}>Please enter a valid email address</span>
          )}
          {/* buttons */}
          <div className="mt-8 flex items-center gap-4 justify-between">
            <button
              type="button"
              onClick={previousStep}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prev
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </div>
        <div className="hidden md:block">
          <Player autoplay loop src="/assets/lottiefiles/step-image-3.json" />
        </div>
      </div>
    </fieldset>
  );
};

export default StepThree;
