import React from "react";
import Container from "../ui/Container";

const features = [
  {
    name: "Health Check",
    description:
      "Review cluster architecture, resources, configuration, usage and data flows",
  },
  {
    name: "Security & Compliance",
    description:
      "Review Authentication, Authorization, Policies, encryption, auditing and check for CVEs",
  },
  {
    name: "Flow development",
    description:
      "Develop NiFi flows to move and transform your data from any source to any destination",
  },
  {
    name: "Extensions",
    description:
      "Develop custom processors, controller services or reporting tasks",
  },
  {
    name: "Monitoring and Alerting",
    description:
      "Monitoring system, flows, custom monitoring and integration",
  },
  {
    name: "Migrations or Upgrade",
    description:
      "Modernize your platform and leverage the advances in the newer version or a different distribution",
  },
];

const CloudManagementSection = () => {
  return (
    <section className="pb-16 md:pb-24">
      <Container>
        <div>
          <div className="mx-auto max-w-2xl lg:text-center">
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Our Services
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600">
            </p>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
              {features.map((feature, index) => (
                <div key={feature.name} className="relative pl-16">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                      <span className="text-white">0{index + 1}</span>
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CloudManagementSection;
