import React from "react";
import Container from "../ui/Container";
// import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

const ContactSection = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const sendEmail = (formData) => {
    emailjs
      .send(
        "service_yqxhg2f",
        "template_wstnrxl",
        {
          full_name: formData.fullname,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        "9Nv59pq5QgveZTB6w"
      )
      .then(
        (result) => {
          reset();
          console.log(result);
          Swal.fire("Perfect!", "Your message has been submitted!", "success");
        }
        // (error) => {
        //   console.log(error.text);
        // }
      )
      .catch((err) => {
        console.log("err", err);
        reset();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went wrong",
        });
      });
  };
  return (
    <section id="contact" className="pb-16 md:pb-24 relative">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-full xl:w-[42%] bg-gray-50" />
      </div>
      <Container>
        <div className="relative lg:grid lg:grid-cols-5">
          <div className="bg-gray-50 px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
            <div className="mx-auto max-w-lg">
              <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                Contact us
              </h2>
              <p className="mt-3 text-lg leading-6 text-gray-500">
                Interested to know more about our services or have a different question?
              </p>
              <p className="mt-4 text-base text-gray-500">
     	        Please leave us a note here and we will get in touch. 
	        If you prefer, you can send an email directly to
                contact@nifi-services.com
              </p>
            </div>
          </div>
          <div className="bg-white px-6 py-16 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12 mt-0 lg:mt-8 xl:mt-0">
            <div className="mx-auto max-w-lg lg:max-w-none">
              <form onSubmit={handleSubmit(sendEmail)} className="grid grid-cols-1 gap-y-6" >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Full name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    id="fullname"
                    autoComplete="name"
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Full name"
                    {...register("fullname", { required: true })}
                  />
                  {errors.from_name && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className={`block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
              errors.email ? "border-red-500" : ""
            }`}
                    placeholder="Email"
		    {...register("email", {
		      required: "This field is required",
		      pattern: {
			value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
			message: "Invalid email address",
		      },
		    })}
                  />
		  {errors.email && (
		    <span className="text-red-500">{errors.email.message}</span>
		  )}
                </div>
                <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
		    className={`block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 ${
		      errors.phone ? "border-red-500" : ""
		    }`}
                    placeholder="Phone"
		    {...register("phone", {
		      required: "This field is required",
		      pattern: {
			value: /^\d{10}$/, // Adjust the regex pattern for phone numbers as needed
			message: "Invalid phone number",
		      },
		    })}
                  />
		  {errors.phone && (
		    <span className="text-red-500">{errors.phone.message}</span>
		  )}
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full rounded-md border-gray-300 px-4 py-3 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Message"
                    defaultValue={""}
                    {...register("message", { required: true })}
                  />
                  {errors.message && (
                    <span className="text-red-500">This field is required</span>
                  )}
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ContactSection;
