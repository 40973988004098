import React from "react";
import Container from "../ui/Container";

const AboutSection = () => {
  return (
    <section id="about" className="pb-16 md:pb-24">
      <Container>
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-4 h-full">
            <div className="h-full relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-lg lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <img
                className="absolute inset-0 h-full w-full object-cover"
                src="/assets/images/about-image.jpg"
                alt="..."
              />
            </div>
          </div>
          <div>
            <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-indigo-600">
                About us
              </p>
              <div className="max-w-xl">
                <p className="mt-6">
	  NiFi Services company is a team of professional developers, data architects, ETL and data engineers with a broad experience in designing and implementing solutions using Apache NiFi, MiNiFi and other services. Our knowledge, skills, and expertise will help unlock the value of your data for the benefit of your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AboutSection;
