import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const StepLast = ({ previousStep, information, files, userInfo, addlQuestions }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    const formdata = new FormData();
    for (let i = 0; i < files.length; i++) {
      formdata.append("files", files[i]);
    }

    const authData = JSON.parse(localStorage.getItem('auth'));
    if (authData && authData.token && authData.user) {
        formdata.append('signedinuser', authData.user.email);
        formdata.append('token', authData.token);
    } else {
        formdata.append('signedinuser', "anonymous");
        formdata.append('token', "undefined");
    }
    formdata.append("email", userInfo?.email);
    formdata.append("questions", addlQuestions?.addl_questions);

    for (let i = 0; i < information.length; i++) {
      formdata.append("systeminformation", information[i].id);
    }

    axios
      .post("/api/aws/uploadfile", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLoading(false);
        navigate("/");
        Swal.fire("Perfect!", "Your Data has been submitted!", "success");
      })
      .catch((err) => {
        setLoading(false);
        console.err(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something Went wrong",
        });
      });
  };

  return (
    <fieldset>
      <div className="grid md:grid-cols-3 gap-8">
        <div className="md:col-span-2">
          <h1 className="mb-3 text-xl sm:text-2xl font-semibold">
            Analysis request details
          </h1>
          <div className="space-y-4">
            <div className="bg-white rounded-lg p-3">
              <h2 className="mb-2 text-lg font-semibold">
                Categories selected
              </h2>
              <ul className="pl-5 list-disc text-base font-medium capitalize grid md:grid-cols-2 gap-2">
                {information?.map((item) => (
	           <li key={item.id}> 
                     <h5>{item.label}</h5>
                   </li>
                ))}
              </ul>
            </div>
            <div className="bg-white rounded-lg p-3">
              <h2 className="mb-2 text-lg font-semibold">Files to be Uploaded</h2>
              <ul className="pl-5 list-disc text-base font-medium grid md:grid-cols-2 gap-2">
                {files?.map((item) => (
                  <li>
                    <h5>{item?.name}</h5>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white rounded-lg p-3">
              <h2 className="mb-2 text-lg font-semibold">
                Reports will be sent to
              </h2>
              <ul className="text-base font-medium  grid md:grid-cols-2 gap-2">
                <li>
                  <div>
                    <h6>{userInfo?.email}</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {/* buttons */}
          <div className="mt-8 flex items-center gap-4 justify-between">
            <button
              type="button"
              onClick={previousStep}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prev
            </button>
            <button
              disabled={loading === true ? true : false}
              type="button"
              onClick={onSubmit}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? "loading" : "Submit"}
            </button>
          </div>
        </div>
        <div className="hidden md:block">
          <Player autoplay loop src="/assets/lottiefiles/step-image-4.json" />
        </div>
      </div>
    </fieldset>
  );
};

export default StepLast;
