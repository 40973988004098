import React, { useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

const StepFour = ({ nextStep, previousStep, setAddlQuestions }) => {
  const [questions, setQuestions] = useState(""); // State to store user input
  const [warning, setWarning] = useState(false);

  // Maximum size in bytes (1 KB)
  const maxBytes = 1024;

  // Function to handle text input change
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Check if the input length exceeds the maximum size
    if (inputValue.length > maxBytes) {
      setWarning(true);
    } else {
      setWarning(false);
      setQuestions(inputValue);
    }
  };

  const handleSubmit = () => {

    let data = {
      addl_questions: questions,
    };

    setAddlQuestions(data);
    nextStep();
  };

  return (
    <fieldset>
      <div className="md:flex md:flex-col gap-8">
        <div className="md:col-span-2">
          <h1 className="mb-3 text-xl sm:text-2xl font-semibold">
            Enter any custom requests (optional)
          </h1>
              <label
                htmlFor="addlQuestions"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
              </label>
                <div className="mt-1">
                  <textarea
                    name="addlQuestions"
                    id="addlQuestions"
                    className="block w-full rounded-md border-0 py-7 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Examples:&#10;1. Find all the connection ids that are load balanced&#10;2. Find all processors using parameter xxx"
                    value={questions}
                    onChange={handleInputChange}
                  />
                </div>
          {warning && (
            <span style={{ color: "red" }}>
              Maximum text size exceeded (1 KB limit).
            </span>
          )}
          {/* buttons */}
          <div className="mt-8 flex items-center gap-4 justify-between">
            <button
              type="button"
              onClick={previousStep}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Prev
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="flex items-center gap-2 rounded-md bg-indigo-600 px-3.5 py-2 text-base font-semibold text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </div>
        {/*<div className="hidden md:block">
          <Player autoplay loop src="/assets/lottiefiles/step-image-3.json" />
        </div>*/}
      </div>
    </fieldset>
  );
};

export default StepFour;
