import React from "react";
import Container from "../ui/Container";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-900">
      <Container>
        <div className="py-4 sm:py-6 lg:py-8">
          <div className="mt-4 border-t border-white/10 pt-4 sm:mt-6 lg:mt-8">
            <p className="text-xs sm:text-sm leading-5 text-gray-400">
              &copy; 2023-2024 NiFi-Services, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
